/* stylelint-disable selector-max-universal */
// this is universal selector rules
* {
	&,
	&::before,
	&::after {
		box-sizing: border-box;
	}

	&::selection {
		background-color: $selection-background-color;
	}


	// stylelint-disable
	&[data-visible="pc"] {
		@media (--sm-lte) {
			display: none !important;
		}
	}

	&[data-visible="sp"] {
		@media (--sm-gt) {
			display: none !important;
		}
	}
	// stylelint-enable
}

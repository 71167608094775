// stylelint-disable selector-class-pattern, selector-nested-pattern
//
// メインコンテンツ（content-mainコンポーネント）の内容はCMSなどで管理されるHTMLを含んだり、
// ページ独自のスタイルを扱うことが多いので例外的に専用のルールを設ける。
//
// メインコンテンツのエレメントは基本的に自由である。
// ただし、クラス名は`c-`で開始してはいけない。もちろん 【`c-` + `コンポーネント名` + `__` + `エレメント名`】 という規則でクラス名をつけない。
// さらにそのルールに従って、メインコンテンツの中にコンポーネントを内包してはいけない。
//
// 🍔 マークは BurgerEditor専用の意味で、利用しない場合は削除する
//
.bge-contents, // 🍔
.c-content-main {
	// 🍔 全ブロック基本の余白
	$bgb-default-margin-bottom: 1.5em;
	// 🍔 背景色・枠線がある場合のパディング
	$bgb-opt-padding: 30px;

	//
	// 基本スタイル
	//
	font-size: 1.6rem;
	line-height: 2;

	// @media (--xs) {
	// 	font-size: calc(14 / 320 * 100vw); // 幅320pxのデバイスで16pt
	// }

	//
	// エレメント
	//
	// ☝ 以降のフォントサイズの指定単位は em を推奨
	//
	a {
		color: currentColor;
	}

	h2 {
		font-size: 2.8rem;
		line-height: 1.7;
		margin: 0 0 50px;
		padding: 5px 0 15px;
		background: url("../img/bg-h2.png") repeat-x left bottom;
		color: $font-primary-color;
		width: calc(100% + 120px);
		margin-right: -60px;
		margin-left: -60px;

		@media (--sm-lte) {
			width: 100%;
			margin: 0 0 25px;
			font-size: 2.2rem;
			padding-bottom: 10px;
			background-size: auto 5px;
		}

		&:first-child {
			margin-top: 0;
		}
	}

	// 🍔
	.bge-title-h2 {
		margin-top: 0;
	}

	h3 {
		font-size: 2.4rem;
		line-height: 1.7;
		margin: 0 0 10px;
		padding: 8px 30px;
		border-radius: 10px;
		background: $yello-color;

		@media (--sm-lte) {
			font-size: 2rem;
			padding: 5px 10px;
		}

		&:first-child {
			margin-top: 0;
		}
	}

	// 🍔
	.bge-title-h3 {
		margin-top: 0;
	}

	h4 {
		font-size: 2.2rem;
		line-height: 1.7;
		margin: 0 0 20px;
		padding: 5px 45px 10px;
		position: relative;
		background: url("../img/bg-h4.png") repeat-x left bottom;

		@media (--sm-lte) {
			font-size: 1.8rem;
			padding: 5px 25px 10px;
		}

		&::before {
			content: "";
			background: url("../img/icon-h4.svg") no-repeat;
			display: block;
			width: 30px;
			height: 30px;
			background-size: 30px 30px;
			position: absolute;
			left: 0;
			top: 10px;

			@media (--sm-lte) {
				width: 20px;
				height: 20px;
				background-size: 20px auto;
			}
		}

		&:first-child {
			margin-top: 0;
		}
	}

	h5 {
		font-size: 2rem;
		color: $secondary-color;
		line-height: 1.7;
		margin: 0 0 1em;
		border-bottom: 1px solid $secondary-color;
		display: inline-block;

		@media (--sm-lte) {
			font-size: 1.6rem;
		}


		&:first-child {
			margin-top: 0;
		}

		a[target="_blank"] {
			text-decoration: none;

			&::after {
			    background-image: url("/img/icon-external02.svg");
			    width: 12px;
			    height: 12px;
			    background-size: 12px 12px;
			    display: inline-block;
			    content: "";
			    margin-left: 10px;
			}

			&:hover {
				opacity: .7;
			}
		}

		& + p {
			margin-top: -10px !important;	// stylelint-disable-line declaration-no-important
		}
	}

	h6 {
		font-size: 1.8rem;
		line-height: 1.7;
		margin: 0 0 1em;
		font-weight: bold;

		@media (--sm-lte) {
			font-size: 1.6rem;
		}

		&:first-child {
			margin-top: 0;
		}
	}

	ul {
		padding-left: 15px;
		margin: 0;

		> li {
			list-style: none;

			&::before {
				content: "";
				display: inline-block;
				vertical-align: middle;
				margin: -3px 7px 0 -15px;
				width: 14px;
				height: 14px;
				border: 4px solid $primary-color;
				border-radius: 50%;
			}

			ul {
				padding-left: 25px;

				ul {
					padding-left: 18px;
				}
			}

			ul li::before {
				background: $pink-color;
				border-color: transparent;
				width: 7px;
				height: 7px;
				margin: -3px 7px 0 -15px;
			}
		}

		ul,
		ol {

		}
	}

	ol {
		counter-reset: content-main-ol-default;
		padding-left: 26px;

		@media (--sm-lte) {
			padding-left: 22px;
		}

		> li {
			list-style: none;

			&::before {
				content: "0"counter(content-main-ol-default, decimal) ". ";
				counter-increment: content-main-ol-default;
				color: $primary-color-darker;
				font-weight: bold;
				font-family: $font-family-lato;
				letter-spacing: .04em;
				display: inline-block;
				margin: -3px 2px 0 -26px;

				@media (--sm-lte) {
					margin-left: -22px;
				}
			}

			li {
				&::before {
					color: $pink-color;
				}
			}
		}

		ul,
		ol {
			//
		}
	}

	table {
		border-collapse: collapse;
		width: 100%;
		margin-bottom: 30px;
	}

	caption {
		font-weight: bold;
		text-align: left;
	}

	tr {
		&:last-child td {
			border-bottom: 0;
		}
	}

	th,
	td {
		padding: 1.3em 1.7em;
		color: $font-primary-color;
		border-bottom: 1px solid $border-primary-color;
	}

	.form_align_top {
		th,
		td {
			vertical-align: top;
		}
	}

	th {
		background: $primary-color;
		text-align: left;
		color: $lightest-color;
		font-size: 1.5rem;
		font-weight: 500;
		border-bottom: 1px solid $lightest-color;

		@media (--sm-lte) {
			padding: 10px;
		}

		& + th {
			border-left: 1px solid $lightest-color;
		}

		.th02 {
			background: #fffce2;
		}
	}

	td {
		font-size: 1.5rem;
		background: $lightest-color;
		border-bottom: 1px solid #d9d9d9;
		border-right: 0;

		@media (--sm-lte) {
			padding: 10px;
		}

		& + td {
			border-left: 1px solid #d9d9d9;
		}
	}

	.bge-type-table {
		table {
			@media (--sm-lte) {
				display: block;
			}
		}

		caption {
			@media (--sm-lte) {
				display: block;
			}
		}

		thead {
			@media (--sm-lte) {
				display: none;
			}
		}

		tfoot {
			@media (--sm-lte) {
				display: none;
			}
		}

		tbody {
			@media (--sm-lte) {
				display: block;
			}
		}

		tr {
			@media (--sm-lte) {
				width: 100%;
				display: block;
			}
		}

		th,
		td {
			@media (--sm-lte) {
				display: block;
				margin-top: -1px;
				width: 100% !important; // stylelint-disable-line declaration-no-important
			}
		}

		tr:not(:last-child) {
			td {
				@media (--sm-lte) {
					border-bottom: none;
				}
			}
		}
	}

	.form_input,
	.content-form__table {
		@media (--sm-lte) {
			display: block;
		}

		caption {
			@media (--sm-lte) {
				display: block;
			}
		}

		thead {
			@media (--sm-lte) {
				display: none;
			}
		}

		tfoot {
			@media (--sm-lte) {
				display: none;
			}
		}

		tbody {
			@media (--sm-lte) {
				display: block;
			}
		}

		tr {
			@media (--sm-lte) {
				width: 100%;
				display: block;
			}
		}

		th,
		td {
			@media (--sm-lte) {
				display: block;
				margin-top: -1px;
				width: 100% !important; // stylelint-disable-line declaration-no-important
			}
		}

		tr:not(:last-child) {
			td {
				@media (--sm-lte) {
					border-bottom: none;
				}
			}
		}
	}

	.scroll-table {
		@media (--xs-lte) {
			overflow: auto;
			margin: 0 0 30px;

			table {
				width: 880px !important; // stylelint-disable-line declaration-no-important
				margin-bottom: 10px;

				&::before {
					content: "※下記表組は、横スクロールしてご覧ください。";
					display: block;
					margin-bottom: 20px;
					font-size: 1.2rem;
					position: sticky;
					top: 10px;
					left: 0;
					white-space: nowrap;
				}
			}

			&::-webkit-scrollbar {
				height: 5px;
			}

			th,
			td {
				padding: 15px;
			}

			&::-webkit-scrollbar {
				height: 10px;
			}

			&::-webkit-scrollbar-thumb {
				border-radius: 5px;
				background: #bbbbbb;
			}

			&::-webkit-scrollbar-track {
				margin: 0 2px;
				background: #eaeaea;
				border-radius: 5px;
			}
		}
	}

	p {
		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	.bgb-image-text2,
	.bgb-image-text3,
	.bgb-image-text4,
	.bgb-image-text5,
	.bgb-image-text6 {
		p {
			margin:0;

			&:first-child {
				padding-right: 0px;
			}

			&:last-child {
				padding-left: 0px;
			}
		}
	}

	.bgb-text-float-image2,
	.bgb-text-float-image1,
	.bgb-text-image2,
	.bgb-text-image1 {
		.bgt-grid--first {
			@media (--sm-lte) {
				margin-bottom: 1em;
			}
		}
	}

	blockquote {
		background: $gray-color;
		margin: 0 0 0 $indent-sub-list;
		padding: 1em 2em;
		position: relative;

		&::before {
			content: "“";
			display: block;
			font-size: calc(40 / 16 * 1em);
			left: 0;
			line-height: 0;
			margin-top: .5em;
			opacity: .2;
			pointer-events: none;
			position: absolute;
			top: 0;
			user-select: none;
		}

		&::after {
			bottom: 0;
			content: "”";
			display: block;
			font-size: calc(40 / 16 * 1em);
			line-height: 0;
			opacity: .2;
			pointer-events: none;
			position: absolute;
			right: 0;
			user-select: none;
		}
	}

	figure {
		margin: 0;
	}

	figcaption {
		background: none;
		font-size: calc(14 / 16 * 1em);
		font-weight: normal;
		line-height: 1.6;
		padding: .8em 1.2em;
		text-align: left;
	}

	hr {
		color: $border-primary-color;
		background: $border-primary-color;
		border-style: none;
		height: 2px;
		margin: 50px auto;
		width: calc(95 / 100 * 100%);

		&:first-child {
			margin-top: 0;
		}
	}

	input {
		font-size: 1.6rem;
		max-width: 100%;

		&[type="text"],
		&[type="email"] {
			border: 1px solid $border-primary-color;
			padding: .5em .3em;
			border-radius: 3px;

			&:focus {
				box-shadow:
					0 0 10px 0 $btn-glow-color,
					0 0 10px 0 $lightest-color;
				outline: none;
			}
		}

		&[type="text"]:not([size]),
		&[type="email"]:not([size]) {
			width: 100%;
		}
	}

	textarea {
		border: 1px solid $border-primary-color;
		font-size: 1.6rem;
		padding: .3em;
		width: 100%;
		resize: vertical;

		&:focus {
			box-shadow:
				0 0 10px 0 $btn-glow-color,
				0 0 10px 0 $lightest-color;
			outline: none;
		}
	}

	// フォーム入力
	table.form_input {
		th.required {
			&::after {
				content: "必須";
				display: inline-block;
				color: $font-primary-color;
				background: $yello-color;
				font-size: 1.2rem;
				line-height: 1;
				padding: 4px 6px;
				border-radius: 2px;
				vertical-align: middle;
				float: right;
				margin-top: 3px;

				@media (--sm-lte) {
					margin-top: 5px;
				}
			}
		}

		th {
			width: 270px;
		}

		select {
			appearance: none;
			border-radius: 0;
			border: 1px solid $border-primary-color;
			margin: 0;
			padding: 5px 25px 5px 10px;
			vertical-align: middle;
			position: relative;
			font-size: 1em;
			background: url("../img/icon-select.png") no-repeat right 10px center;
			background-size: 10px 5px;
		}

		td {
			label {
				margin-left: 5px;

				& + input {
					margin-left: 15px;
				}
			}
		}
	}

	.btn-group {
		margin: 50px auto 0;
		text-align: center;
		display: flex;
		justify-content: center;

		@media (--sm-lte) {
			display: block;
			margin: 30px auto 0;
		}

		div[data-bgt-button-kind="back"] {
			&::before {
				content: "";
				display: block;
				background: url("../img/icon-ar-back.svg") no-repeat;
				width: 17px;
				height: 14px;
				background-size: 17px auto;
				position: absolute;
				z-index: 50;
				transform: translate(0, -50%);
				left: 20px;
				top: 50%;
				transition: 200ms;
			}

			&:hover {
				input {
					background: #aaa;
					border-color: #aaa;
				}

				&::before {
					left: 17px;
				}
			}
		}

		div {
			margin: 0 10px;
			position: relative;

			@media (--sm-lte) {
				margin: 0 0 15px;
			}

			&.submit {
				input {
					background-color: $secondary-color;
				}

				&::after {
					content: "";
					display: block;
					background: url("../img/icon-ar.svg") no-repeat;
					width: 17px;
					height: 14px;
					background-size: 17px auto;
					position: absolute;
					z-index: 50;
					transform: translate(0, -50%);
					right: 20px;
					top: 50%;
					transition: 200ms;

					@media (--sm-lte) {
						right: calc(50% - 90px);
					}
				}

				&:hover {
					input {
						border-color: $secondary-color;
						color: $font-primary-color;
						background: $lightest-color;
					}

					&::after {
						right: 17px;
						background-image: url("../img/icon-ar-right-blue.svg");

						@media (--sm-lte) {
							right: calc(50% - 100px);
						}
					}
				}
			}
		}
	}

	//
	// 💊 ヘルパークラス
	//
	// 👇 以下 👇
	//
	// 🚫 削除非推奨 🚫
	//
	%grid-frame {
		align-content: flex-start;
		align-items: flex-start;
		display: flex;
		flex-flow: row wrap;
		justify-content: flex-start;
	}

	.grid-frame {
		@extend %grid-frame;
	}

	.grid-frame-flex {
		@extend %grid-frame;
		flex-wrap: nowrap;
	}

	@for $i from 1 to 12 {
		.grid-xs-$(i) { @media (--xs) { flex-grow: 0; flex-shrink: 0; flex-basis: calc($i / 12 * 100%); } }
		.grid-sm-$(i) { @media (--sm) { flex-grow: 0; flex-shrink: 0; flex-basis: calc($i / 12 * 100%); } }
		.grid-md-$(i) { @media (--md) { flex-grow: 0; flex-shrink: 0; flex-basis: calc($i / 12 * 100%); } }
		.grid-lg-$(i) { @media (--lg) { flex-grow: 0; flex-shrink: 0; flex-basis: calc($i / 12 * 100%); } }
		.grid-xl-$(i) { @media (--xl) { flex-grow: 0; flex-shrink: 0; flex-basis: calc($i / 12 * 100%); } }
	}

	.grid-xs-d5 { @media (--xs) { flex-grow: 0; flex-shrink: 0; flex-basis: calc(1 / 5 * 100%); } }
	.grid-sm-d5 { @media (--sm) { flex-grow: 0; flex-shrink: 0; flex-basis: calc(1 / 5 * 100%); } }
	.grid-md-d5 { @media (--md) { flex-grow: 0; flex-shrink: 0; flex-basis: calc(1 / 5 * 100%); } }
	.grid-lg-d5 { @media (--lg) { flex-grow: 0; flex-shrink: 0; flex-basis: calc(1 / 5 * 100%); } }
	.grid-xl-d5 { @media (--xl) { flex-grow: 0; flex-shrink: 0; flex-basis: calc(1 / 5 * 100%); } }
	.grid-xs-flex { @media (--xs) { flex: 0 1 100%; } }
	.grid-sm-flex { @media (--sm) { flex: 0 1 100%; } }
	.grid-md-flex { @media (--md) { flex: 0 1 100%; } }
	.grid-lg-flex { @media (--lg) { flex: 0 1 100%; } }
	.grid-xl-flex { @media (--xl) { flex: 0 1 100%; } }

	// マージンヘルパー
	.mt-zero {
		margin-top: 0;
	}

	.mb-zero {
		margin-bottom: 0;
	}

	.mt-narrow {
		// stylelint-disable at-rule-empty-line-before
		@media (--xs) { margin-top: $margin-narrow-xs; }
		@media (--sm) { margin-top: $margin-narrow-sm; }
		@media (--md) { margin-top: $margin-narrow-md; }
		@media (--lg) { margin-top: $margin-narrow-lg; }
		@media (--xl) { margin-top: $margin-narrow-xl; }
		// stylelint-enable at-rule-empty-line-before
	}

	.mt-wide {
		// stylelint-disable at-rule-empty-line-before
		@media (--xs) { margin-top: $margin-wide-xs; }
		@media (--sm) { margin-top: $margin-wide-sm; }
		@media (--md) { margin-top: $margin-wide-md; }
		@media (--lg) { margin-top: $margin-wide-lg; }
		@media (--xl) { margin-top: $margin-wide-xl; }
		// stylelint-enable at-rule-empty-line-before
	}

	.mb-narrow {
		// stylelint-disable at-rule-empty-line-before
		@media (--xs) { margin-bottom: $margin-narrow-xs; }
		@media (--sm) { margin-bottom: $margin-narrow-sm; }
		@media (--md) { margin-bottom: $margin-narrow-md; }
		@media (--lg) { margin-bottom: $margin-narrow-lg; }
		@media (--xl) { margin-bottom: $margin-narrow-xl; }
		// stylelint-enable at-rule-empty-line-before
	}

	.mb-wide {
		// stylelint-disable at-rule-empty-line-before
		@media (--xs) { margin-bottom: $margin-wide-xs; }
		@media (--sm) { margin-bottom: $margin-wide-sm; }
		@media (--md) { margin-bottom: $margin-wide-md; }
		@media (--lg) { margin-bottom: $margin-wide-lg; }
		@media (--xl) { margin-bottom: $margin-wide-xl; }
		// stylelint-enable at-rule-empty-line-before
	}
	//
	// 💊 ヘルパークラス
	//
	// 👆 以上 👆
	//

	//
	// 🍔 BurgerEditor用
	//
	// 👇 以下 👇
	//
	// 必要ない場合は削除する 🔪
	//
	[data-bgb] {
		margin-bottom: $bgb-default-margin-bottom;
	}

	// ボタンタイプ
	.bgt-btn {
		appearance: none;
		background: $lightest-color;
		border: none;
		color: $font-primary-color;
		display: inline-block;
		font-size: 1.6rem;
		font-weight: bold;
		line-height: 2;
		min-width: 15em;
		padding: .5em 3em;
		position: relative;
		text-align: center;
		text-decoration: none;
		vertical-align: middle;
		border-radius: 40px;
		border: 3px solid $secondary-color;
		transition: 300ms;
		letter-spacing: .02em;

		&:hover {
			background-color: $secondary-color;
			color: $lightest-color;

			&::after {
				right: 17px;
				background-image: url("../img/icon-ar.svg");
			}
		}

		&.btn-em-submit {
			color: $lightest-color;
			cursor: pointer;

			&::after {
				background-image: url("../img/icon-ar.svg");
			}

			&:hover {
				border-color: $secondary-color;
				color: $font-primary-color;
				background: $lightest-color;

				&::after {
					right: 17px;
					background-image: url("../img/icon-ar-right-blue.svg");
				}
			}
		}

		&:active,
		&:focus {
			box-shadow:
				0 0 10px 0 $btn-glow-color,
				0 0 10px 0 $lightest-color;
		}

		&:disabled {
			opacity: .6;
		}

		&::after {
			content: "";
			display: block;
			background: url("../img/icon-ar-right-blue.svg") no-repeat;
			width: 17px;
			height: 14px;
			background-size: 17px auto;
			position: absolute;
			transform: translate(0, -50%);
			right: 20px;
			top: 50%;
			transition: 200ms;
		}
	}

	[data-bgt-button-kind="link"] .bgt-btn {
		//
	}

	[data-bgt-button-kind="em"] .bgt-btn {
		background: $primary-color;
		color: $lightest-color;
		border-color: $primary-color;

		&::after {
			background-image: url("../img/icon-ar.svg");
		}

		&:hover {
			color: $primary-color;
			background: $lightest-color;

			&::after {
				background-image: url("../img/icon-ar-right-green.svg");
			}
		}
	}

	[data-bgt-button-kind="external"] .bgt-btn {
		background: $font-primary-color;
		color: $lightest-color;
		font-weight: 500;
		border-color: $font-primary-color;

		&:hover {
			opacity: .7;
		}

		&::after {
			background-image: url("../img/icon-external.svg");
			width: 12px;
			height: 12px;
			background-size: 12px 12px;
			right: 20px !important;
		}
	}

	[data-bgt-button-kind="back"] .bgt-btn {
		background: #ccc;
		color: $lightest-color;
		border-color: #ccc;
		font-weight: 500;
		cursor: pointer;

		&::before {
			content: "";
			display: block;
			background: url("../img/icon-ar-back.svg") no-repeat;
			width: 17px;
			height: 14px;
			background-size: 17px auto;
			position: absolute;
			transform: translate(0, -50%);
			left: 20px;
			top: 50%;
			transition: 200ms;
		}

		&:hover {
			background: #aaa;
			border-color: #aaa;

			&::before {
				left: 17px;
			}
		}

		&::after {
			display: none;
		}
	}

	.bgb-button3 {
		[data-bgt="button"] {
			@media (--sm-gt) {
				width: 100%;
			}
		}
		a {
			@media (--sm-gt) {
				min-width: inherit;
				width: 100%;
			}
		}
	}

	.bgt-hr {
		margin: 0 auto;
	}

	[data-bgt-hr-kind="dashed"] .bgt-hr {
		height: 0;
		border: none;
		background: none;
		border-bottom: 2px dashed currentColor;
	}

	[data-bgt-hr-kind="bold"] .bgt-hr {
		height: 5px;
	}

	[data-bgt-hr-kind="narrow"] .bgt-hr {
		height: 1px;
	}

	[data-bgt-hr-kind="short"] .bgt-hr {
		width: calc(60 / 100 * 100%);
		height: 1px;
	}

	// ダウンロードファイルタイプ
	[data-bgb="download-file"] [data-bgt="download-file"] {
		@media (--sm-lte) {
			display: block;
		}
	}
	.bgt-download-file__link {
		appearance: none;
		background: $font-primary-color;
		border: none;
		color: $lightest-color;
		display: inline-block;
		font-size: 1.6rem;
		font-weight: 500;
		line-height: 2;
		width: 430px;
		padding: .5em 3em;
		border: 3px solid $font-primary-color;
		position: relative;
		text-decoration: none;
		vertical-align: middle;
		border-radius: 40px;
		transition: 300ms;
		text-align: center;

		@media (--sm-lte) {
			width: 100%;
		}

		i {
			display: none;
		}

		&:active,
		&:focus {
			box-shadow:
				0 0 10px 0 $btn-glow-color,
				0 0 10px 0 $lightest-color;
		}

		&::after {
			content: "";
			display: block;
			background: url("../img/icon-pdf.png");
			margin-top: -11px;
			position: absolute;
			right: 20px;
			top: 50%;
			width: 19px;
			height: 22px;
		}

		&:hover {
			opacity: .7;
		}
	}

	.bgb-download-file2 {
		.bgt-grid {
			div {
				width: 100%;
			}

			a {
				width: 100%;
			}
		}

		& + .bgb-download-file2 {
			@media (--sm-lte) {
				margin-top: -0.5em;
			}
		}
	}

	.bgb-download-file3 {
		@media (--sm-gt) {
			display: flex;
			flex-flow: nowrap;

			.bgt-grid {
				width: 98%;
				div {
					margin: 0;
				}

				a {
					width: 100%;
					padding: .5em 2.2em;

					&::after {
						right: 10px;
					}
				}
			}
		}
		.bgt-grid {
			& + .bgt-grid {
				@media (--sm-gt) {
					margin-left: 3%;
				}
				@media (--sm-lte) {
					margin-top: 1.5em;
				}
			}
		}
	}

	.bgt-link__size {
		font-size: calc(13 / 16 * 1em);
		font-weight: normal;
		opacity: .7;
	}

	.bgt-link__icon {
		&::after {
			vertical-align: middle;
		}
	}

	.bgt-box__image-container,
	.bgt-image__link,
	.bgt-trimmed-image-link__link {
		border-radius: 10px;
		overflow: hidden;
	}

	// Google Maps
	.bgt-google-maps-link {
		@media (--xs) {
			border: solid 1px $gray-color03;
			border-radius: 3px;
			color: $gray-color04;
			margin-top: 10px;
			padding: 1em;
			text-align: center;
			text-decoration: none;

			span {
				display: inline-block;
				vertical-align: middle;
			}

			&::after {
				@mixin bge-icon;
				content: $fa-external-link;
				font-size: 1em;
				vertical-align: middle;
			}
		}
	}

	// 画像リンクのフォーカス
	.bgt-image {
		:link { // stylelint-disable-line selector-pseudo-class-blacklist
			outline: none; // 🍔 Burger ISSUE 👎
		}
	}

	//
	// ブロックオプション用
	//

	// 背景色
	.bgb-opt--bg-gray,
	.bgb-opt--bg-blue,
	.bgb-opt--bg-pink {
		padding: $bgb-opt-padding;
	}

	// 枠線 - スタイル
	.bgb-opt--border-none {
		//
	}

	.bgb-opt--border-bold,
	.bgb-opt--border-thin,
	.bgb-opt--border-dotted {
		border-color: #f9a1ba;
		border-radius: 10px;
		background: $lightest-color;
	}

	.bgb-opt--border-bold {
		border-width: 3px;
	}

	// 枠線 - 適用箇所
	.bgb-opt--border-trbl {
		padding: $bgb-opt-padding;
	}

	.bgb-opt--border-td, // クラス名のタイポ // 🍔 Burger ISSUE 👎
	.bgb-opt--border-tb {
		border-left-width: 0 !important; // stylelint-disable-line declaration-no-important
		border-right-width: 0 !important; // stylelint-disable-line declaration-no-important
		padding: $bgb-opt-padding;
		border-radius: 0;
	}

	.bgb-opt--border-lr {
		padding-left: $bgb-opt-padding;
		padding-right: $bgb-opt-padding;
		border-radius: 0;
	}

	.bgb-opt--border-trl {
		padding-left: $bgb-opt-padding;
		padding-right: $bgb-opt-padding;
		padding-top: $bgb-opt-padding;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}

	.bgb-opt--border-rbl {
		padding-bottom: $bgb-opt-padding;
		padding-left: $bgb-opt-padding;
		padding-right: $bgb-opt-padding;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}

	// 余白
	.bgb-opt--mb-large {
		margin-bottom: 6rem !important; // stylelint-disable-line declaration-no-important
	}

	.bgb-opt--mb-small {
		margin-bottom: 1em !important; // stylelint-disable-line declaration-no-important
	}

	.bgb-opt--mb-none {
		margin-bottom: 0 !important; // stylelint-disable-line declaration-no-important
	}

	// Wysiwygタイプと画像の余白
	[data-bgt="ckeditor"] {
		&:nth-child(2) {
			margin-top: 1em;
		}
	}

	// galleryタイプ
	[data-bgt="gallery"] {
		$thumb-size: calc(60 / 16 * 1em);

		.bgt-gallery {
			padding-top: calc(9 / 16 * 100%);

			&:hover {
				.bgt-gallery-ctrl {
					opacity: 1;
				}
			}

			&__img {
				img {
					object-fit: cover;
				}
			}

			&__caption {
				color: $lightest-color;
				background: color($darkest-color a(.6));
				padding: .7em 1em;
				text-align: center;
			}

			&-ctrl {
				top: 50%;
				opacity: 0;
				transition: opacity 200ms ease-in;

				&__prev,
				&__next {
					display: block;
					appearance: none;
					margin: -20px 0 0;
					padding: 0;
					background: none;
					border: none;
					transform-origin: center center;

					&:hover {
						opacity: .5;
					}

					&:focus {
						outline: none;
					}

					span {
						display: block;
						overflow: hidden;
						width: 0;
						height: 0;
					}

					&::after {
						content: "";
						display: block;
						width: 40px;
						height: 40px;
						border: solid $lightest-color;
						border-width: 5px 5px 0 0;
					}
				}

				&__prev {
					left: 20px;
					transform: rotate(-135deg);
				}

				&__next {
					right: 20px;
					transform: rotate(45deg);
				}
			}
		}

		.bgt-gallery-marker {
			li {
				&::before {
					display: none;
				}
			}
		}

		[data-gallery-marker="thumbs"] {
			+ .bgt-gallery-marker {
				margin-top: 10px;

				li {
					margin: 0 5px 10px;
					width: $thumb-size;
					height: $thumb-size;
				}
			}
		}

		[data-gallery-marker="dot"] {
			+ .bgt-gallery-marker {
				margin-top: 10px;

				li {
					margin: 0 2px;
					width: 10px;
					height: 10px;
					background-color: $primary-color;
					border: 1px solid $brown-color;
					border-radius: 100%;
					opacity: .6;

					&.current {
						opacity: 1;
					}
				}
			}
		}
	}
	//
	// 👆 以上 👆
	//
	// 🍔 BurgerEditor用
	//

	//
	// 👇 以下 👇
	//
	// 🐲 baserCMS用
	//
	// 必要ない場合は削除する 🔪
	//
	.bc-heading {
		h2 {
			//
		}
	}

	.bc-time {
		margin-bottom: 5em;
		text-align: right;

		time {
			//
		}
	}

	.bc-cat {
		margin-bottom: 1em;
		text-align: right;

		> div {
			background: $primary-color;
			color: $lightest-color;
			display: inline-block;
			font-size: calc(13 / 16 * 1em);
			padding: .2em .4em;
		}
	}

	.bc-area-cta {
		margin-top: 4em;
		text-align: center;

		a {
			background: $primary-color;

			span {
				&::before {
					@mixin icon;
					color: $gray-color03;
					content: $glyph-email;
					margin-right: .5em;
				}
			}
		}
	}

	.bc-area-ctrl {
		margin-top: 6em;

		@media (--xs) {
			text-align: center;
		}
	}

	.bc-form-table {
		//
	}

	.bc-form-agreement {
		margin-top: 4em;
		text-align: center;
	}

	.bc-form-submit {
		margin-top: 3em;
		text-align: center;
	}

	// お問い合わせbox
	.contact-box {
		text-align: center;

		h5 {
			& + p {
				margin-top: 0;
			}
		}
	}

	// indexパーツ
	.index-block {
		@media (--sm-gt) {
			display: flex;
			justify-content: space-between;

			&::after {
				display: none;
			}
		}

		.bgt-grid {
			display: flex;
			border-radius: 10px;
			overflow: hidden;
			float: none;
			padding: 0;

			@media (--sm-gt) {
				width: 48%;
			}

			@media (--sm-lte) {
				margin-bottom: 1.5em;
			}

			&.no-contents {
				display: none;
			}

			> div {
				&[data-bgt="image-link"] {
					width: 38%;

					* {
						height: 100%;
					}

					.bgt-box__image-container {
						border-radius: 0;

						&:hover {
							opacity: .7;
						}

						img {
							width: 100%;
							height: 100%;
							display: block;
							object-fit: cover;
						}
					}
				}

				&[data-bgt="ckeditor"] {
					width: 62%;
					background: $lightest-color;
					margin: 0;
					height: 100%;

					div {
						height: 100%;
					}

					a {
						height: 100%;
						padding: 40px 25px;
						display: flex;
						align-items: center;
						justify-content: center;
						flex-flow: column;
						text-decoration: none;
						position: relative;
						overflow: hidden;

						@media (--sm-lte) {
							padding: 5vw 3vw;
						}

						&:hover {
							opacity: .7;
						}

						&::before,
						&::after {
							display: block;
							content: "";
							position: absolute;
						}

						&::before {
							width: 17px;
							height: 15px;
							background: url("../img/icon-ar-right-white.svg") no-repeat left top;
							background-size: 17px 15px;
							z-index: 50;
							right: 5px;
							bottom: 8px;

							@media (--sm-lte) {
								width: 12px;
								height: 13px;
								background-size: 12px 13px;
							}
						}

						&::after {
							width: 80px;
							height: 80px;
							background: #0bb3c8;
							transform: rotate(45deg);
							right: -40px;
							bottom: -40px;

							@media (--sm-lte) {
								width: 60px;
								height: 60px;
								right: -30px;
								bottom: -30px;
							}
						}

						h3 {
							background: none;
							font-size: 2.2rem;
							color: #584b3b;
							text-align: center;
							line-height: 1.5;
							font-weight: 500;
							padding: 0;
							margin: 0 0 10px;

							@media (--sm-lte) {
								font-size: 5vw;
							}

							small {
								font-size: 1.5rem;

								@media (--sm-lte) {
									font-size: 3vw;
								}
							}

							&::after {
								content: "";
								display: block;
								background: #0bb3c8;
								width: 105px;
								height: 1px;
								margin: 8px auto 0;
							}

							+ p {
								text-align: center;
								color: #0bb3c8;
								font-family: $font-family-lato;
								font-size: 1.1rem;
								font-weight: 600;
								letter-spacing: 1px;
								line-height: 1.2;
								margin: 0;

								@media (--sm-lte) {
									font-size: 2vw;
								}
							}
						}
					}
				}
			}
		}
	}

	// ローカルナビ
	.nav-local {
		padding: 25px;
		background: url("../img/bg-repeat-02.gif") repeat left bottom;

		ul {
			display: flex;
			flex-flow: wrap;
			justify-content: flex-start;
			padding: 0;
			margin-bottom: -15px;

			li {
				margin-bottom: 15px;
				background: $lightest-color;
				border-radius: 5px;

				@media (--sm-gt) {
					width: calc(25% - 13px);
					margin: 0 15px 15px 0;
				}

				@media (--sm-lte) {
					width: 100%;
				}

				&::before {
					display: none;
				}

				&:nth-child(4n) {
					margin-right: 0;
				}

				a {
					display: flex;
					justify-content: center;
					height: 100%;
					align-items: center;
					text-align: center;
					font-weight: 500;
					font-size: 1.45rem;
					color: #584b3b;
					text-decoration: none;
					padding: 15px;
					line-height: 1.5;
					position: relative;
					overflow: hidden;
					border-radius: 5px;

					&:hover {
						opacity: .7;
					}

					&::before,
					&::after {
						display: block;
						content: "";
						position: absolute;
					}

					&::before {
						width: 12px;
						height: 13px;
						background: url("../img/icon-ar-right-white.svg") no-repeat left bottom;
						background-size: 12px 13px;
						z-index: 50;
						right: 5px;
						bottom: 5px;
					}

					&::after {
						width: 55px;
						height: 55px;
						background: #f896b2;
						transform: rotate(45deg);
						right: -27px;
						bottom: -27px;
					}
				}
			}
		}
	}

	// anchorリンク
	.anchor-link {
		padding: 25px;
		background: url("../img/bg-repeat-02.gif") repeat left bottom;

		ul {
			display: flex;
			flex-flow: wrap;
			justify-content: flex-start;
			padding: 0;
			margin-bottom: -15px;

			li {
				margin-bottom: 15px;
				background: $lightest-color;
				border-radius: 5px;

				@media (--sm-gt) {
					width: calc(20% - 8px);
					margin: 0 10px 10px 0;
					padding: 0;
				}

				@media (--sm-lte) {
					width: 100%;
				}

				&::before {
					display: none;
				}

				&:nth-child(5n) {
					margin-right: 0;
				}

				a {
					display: flex;
					justify-content: center;
					height: 100%;
					align-items: center;
					text-align: center;
					font-weight: 500;
					font-size: 1.45rem;
					color: #584b3b;
					text-decoration: none;
					padding: 15px 28px 15px 12px;
					line-height: 1.5;
					position: relative;
					overflow: hidden;

					&:hover {
						opacity: .7;
					}

					&::before {
						display: block;
						content: "";
						position: absolute;
						width: 18px;
						height: 18px;
						background: $primary-color-lighter02 url("../img/icon-ar-right-white02.svg") no-repeat center center;
						background-size: 9px auto;
						z-index: 50;
						right: 7px;
						top: 50%;
						margin-top: -8px;
						text-align: center;
						border-radius: 50%;
					}
				}
			}
		}
	}

	// 1日の流れ
	.oneday-contents,
	.oneday-contents__notime {
		position: relative;

		&::before {
			display: block;
			content: "";
			position: absolute;
			left: 38px;
			top: 0;
			width: 10px;
			height: 100%;
			// border-left: 5px dotted #82ddeb;
			background: url("/img/bg-repeat-oneday.svg") repeat-y -5px top;
			background-size: 10px auto;

			@media (--sm-lte) {
				left: 9vw;
			}
		}

		&.oneday-last,
		&.oneday-last02 {
			&:not(.oneday-subttl) {
				&::before {
					display: none !important;
				}
			}

			&.oneday-subttl {
				padding-bottom: 0;

				&::before {
					height: 80%;
				}
			}
		}

		&.oneday-last {
			margin-bottom: 6rem !important; // stylelint-disable-line declaration-no-important
		}
	}

	.oneday-nav {
		ul {
			padding: 0;
			display: flex;
			flex-flow: nowrap;
			justify-content: space-between;

			li {
				width: 49%;

				&::before {
					display: none;
				}

				a {
					display: flex;
					justify-content: center;
					align-items: center;
					height: 100%;
					color: $lightest-color;
					font-size: 2.2rem;
					font-weight: 500;
					letter-spacing: 2px;
					line-height: 1.5;
					text-align: center;
					padding: 10px;
					text-decoration: none;
					background: #2fc7de;
					position: relative;
					border-top-left-radius: 10px;
					border-top-right-radius: 10px;

					@media (--sm-lte) {
						font-size: 3vw;
					}

					&.current {
						pointer-events: none;

						&::after {
							display: block;
							position: absolute;
							bottom: -7px;
							left: 50%;
							margin-left: -10px;
							width: 25px;
							height: 25px;
							content: "";
							background: #2fc7de;
							transform: rotate(45deg);
							z-index: -1;
						}
					}
				}

				&:nth-child(1) {
					a {
						background: $pink-color;

						&.current {
							&::after {
								background: $pink-color;
							}
						}
					}
				}

				&:nth-child(2n) {
					a {
						background: #7bc828;

						&.current {
							&::after {
								background: #7bc828;
							}
						}
					}
				}
			}
		}
	}

	.oneday-ttl {
		position: relative;
		z-index: 50;
		margin-bottom: 0;


		&:not(.oneday-contents__notime) {
			&::after {
				display: none;
				content: "";
				position: absolute;
				left: 38px;
				top: 0;
				width: 10px;
				height: 100%;
				// border-left: 5px dotted #82ddeb;
				background: url("/img/bg-repeat-oneday.svg") repeat-y -5px top;
				background-size: 10px auto;


				@media (--sm-lte) {
					left: 9vw;
				}
			}
		}

		h2 {
			font-size: 5rem;
			color: #695741;
			font-weight: 600;
			background: none;
			padding: 0;
			padding-left: 110px;
			position: relative;
			margin: 0;
			width: 100%;
			line-height: 1.5;

			@media (--sm-lte) {
				font-size: 8vw;
				padding-left: 23vw;
			}

			span {
				position: absolute;
				top: -2px;
				left: 0;
				display: flex;
				flex-flow: column;
				align-items: center;
				justify-content: center;
				width: 80px;
				height: 80px;
				border-radius: 50%;
				padding: 5px;
				color: $lightest-color;
				font-family: $font-family-lato;
				font-size: 2.3rem;
				line-height: 1;
				text-align: center;
				font-weight: 500;
				background: #2fc7de;
				letter-spacing: 1px;

				@media (--sm-lte) {
					width: 18vw;
					height: 18vw;
					font-size: 5vw;
					top: -2vw;
				}

				&::after {
					display: block;
					position: absolute;
					bottom: 50%;
					right: -10px;
					margin-bottom: -17px;
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 15px 0 15px 20px;
					border-color: transparent transparent transparent #2fc7de;
					content: "";
					z-index: -1;

					@media (--sm-lte) {
						border-width: 5vw 0 5vw 10vw;
						margin-bottom: -5vw;
						right: -2.5vw;
					}
				}

				small {
					font-family: $font-family-primary;
					font-size: 1.4rem;
					margin: 3px 0 -6px;

					@media (--sm-lte) {
						font-size: 3vw;
					}
				}
			}
		}

		&.oneday-ttl-box {
			&::before {
				display: none;
			}
		}

		& + .oneday-box {
			margin: -40px 0 0;

			@media (--sm-lte) {
				margin-top: -6vw;
			}

			&::before {
				display: block;
				top: 45px;

				@media (--sm-lte) {
					top: inherit;
					bottom: 0;
				}
			}
		}

		& + .oneday-ttl {
			margin-top: 40px;

			@media (--sm-lte) {
				margin-top: 13vw;
			}

			&:not(.oneday-contents__notime) {
				&::after {
					display: block;
					top: -33px;
					z-index: -1;

					@media (--sm-lte) {
						top: -12vw;
					}
				}
			}
		}
	}

	.oneday-subttl {
		position: relative;
		padding: 0 0 50px 110px;
		margin: 0;

		@media (--sm-lte) {
			padding-left: 23vw;
		}

		& + .oneday-subttl.oneday-last {
			&::before {
				height: 27px;
			}
		}

		h3 {
			position: relative;
			font-size: 2.4rem;
			line-height: 1.3;
			color: #695741;
			letter-spacing: 1px;
			padding: 5px 30px;
			background: $lightest-color;
			text-align: center;
			display: inline-block;
			vertical-align: middle;
			border: 3px solid #2fc7de;
			border-radius: 30px;
			margin: 0;

			@media (--sm-lte) {
				font-size: 5vw;
			}

			&::before {
				content: "";
				width: 22px;
				height: 22px;
				background: #2fc7de;
				display: inline-block;
				border-radius: 50%;
				position: absolute;
				left: -84px;
				top: 50%;
				margin-top: -10px;

				@media (--sm-lte) {
					left: -16.5vw;
					width: 5vw;
					height: 5vw;
					margin-top: -2.4vw;
				}
			}

			&::after {
				content: "";
				width: 65px;
				height: 3px;
				background: #2fc7de;
				display: inline-block;
				position: absolute;
				left: -62px;
				top: 50%;
				margin-top: 0;
				z-index: -1;

				@media (--sm-lte) {
					left: -16vw;
					width: 20vw;
				}
			}
		}
	}

	.oneday-box {
		background: $lightest-color;
		border-radius: 10px;
		padding: 60px 40px 60px 120px;
		position: relative;
		margin: 0;
		overflow: hidden;

		@media (--sm-lte) {
			padding: 12vw 8vw 6vw 23vw;
		}

		&::before {
			display: none;
		}

		ul,
		ol {
			& + * {
				margin-top: 20px;
			}
		}

		& + .oneday-box {
			margin-top: -40px;
			padding-top: 0;

			@media (--sm-lte) {
				margin-top: -4.2vw;
			}

			&[data-bgb^="image"] {
				@media (--sm-lte) {
					margin-top: -2vw;
				}
			}

			&::before {
				display: block;

				@media (--sm-lte) {
					//
				}
			}
		}

		& + .oneday-subttl {
			padding-top: 50px;

			&::before {
				top: 5px;
			}
		}

		.oneday-list {
			display: flex;
			flex-flow: wrap;
			padding: 0;

			li {
				margin: 0 10px 10px 0;
				background: #eee4bd;
				border-radius: 30px;
				padding: 10px 30px;
				line-height: 1.3;
				font-size: 1.6rem;
				text-align: center;
				min-width: 200px;
				position: relative;

				&::before {
					margin: -7px 0 0 0;
					position: absolute;
					top: 50%;
					left: 10px;
				}
			}
		}

		& + .oneday-ttl {
			margin-top: 50px;

			&:not(.oneday-contents__notime) {
				&::after {
					display: block;
					top: -48px;
					z-index: -1;
				}
			}
		}
	}

	.oneday-contents__notime {
		padding-left: 60px;

		@media (--sm-lte) {
			padding-left: 8vw;
		}

		&::before,
		&::after {
			display: none !important; // stylelint-disable-line declaration-no-important
		}

		.bge-title-h2 {
			padding-left: 0;
		}
	}

	// 年間行事
	.year-contents {
		@media (--sm-gt) {
			display: flex;
			justify-content: space-between;
		}

		& + .year-contents {
			margin-top: -1em;

			@media (--sm-lte) {
				margin-top: -3em;
				padding-top: 5vw;
			}
		}

		.bgt-grid {
			float: none;
			position: relative;
			padding: 40px 0 0 25px;
			margin: 0;

			@media (--sm-gt) {
				width: 48%;
			}

			@media (--sm-lte) {
				margin-bottom: 5vw;
				padding-top: 10vw;
			}

			&.no-contents {
				display: none;
			}

			.bgt-image-container {
				background: $lightest-color;
				padding: 15px;
				border-top-left-radius: 10px;
				border-top-right-radius: 10px;
				margin-bottom: 0;

				.bgt-image {
					margin-bottom: 0;
				}
			}

			.bgt-ckeditor-container {
				margin-top: 0;
				background: $lightest-color;
				padding: 5px 30px 15px;
				border-bottom-left-radius: 10px;
				border-bottom-right-radius: 10px;

				.bge-ckeditor {
					@media (--sm-lte) {
						margin-bottom: 0;
					}

					> div {
						&::before {
							content: "";
							display: block;
							width: 120px;
							height: 120px;
							position: absolute;
							left: 0;
							top: 0;
							z-index: 50;
							background-size: 120px auto !important;	// stylelint-disable-line declaration-no-important

							@media (--sm-lte) {
								width: 20vw;
								height: 20vw;
								background-size: 100% auto !important;	// stylelint-disable-line declaration-no-important
							}
						}
					}

					.years_apr {
						&::before {	/* stylelint-disable */	/* stylelint-enable */
							background: url("../img/icon-year-apr.svg") no-repeat left top;
						}
					}

					.years_may {
						&::before { background: url("../img/icon-year-may.svg") no-repeat left top; }
					}

					.years_jun {
						&::before { background: url("../img/icon-year-jun.svg") no-repeat left top; }
					}

					.years_jul {
						&::before { background: url("../img/icon-year-jul.svg") no-repeat left top; }
					}

					.years_sep {
						&::before { background: url("../img/icon-year-sep.svg") no-repeat left top; }
					}

					.years_oct {
						&::before { background: url("../img/icon-year-oct.svg") no-repeat left top; }
					}

					.years_nov {
						&::before { background: url("../img/icon-year-nov.svg") no-repeat left top; }
					}

					.years_dec {
						&::before { background: url("../img/icon-year-dec.svg") no-repeat left top; }
					}

					.years_jan {
						&::before { background: url("../img/icon-year-jan.svg") no-repeat left top; }
					}

					.years_feb {
						&::before { background: url("../img/icon-year-feb.svg") no-repeat left top; }
					}

					.years_mar {
						&::before { background: url("../img/icon-year-mar.svg") no-repeat left top; }
					}
				}
			}
		}
	}
}

.content-form__info {
	margin: 0 0 70px;

	@media (--sm-lte) {
		margin: 0 0 30px;
	}

	h2 {
		margin-bottom: 50px;

		@media (--sm-lte) {
			margin-bottom: 20px;
		}
	}

	h3 {
		margin: 0 0 50px;

		@media (--sm-lte) {
			margin: 0 0 15px;
		}
	}

	table {
		th {
			width: 270px;
		}
	}
}

.form__lead {
	margin: 0 0 30px;

	@media (--sm-lte) {
		margin: 0 0 20px;
	}

	p {
		font-size: 1.5rem;

		@media (--sm-lte) {
			font-size: 1.4rem;
		}
	}

	span.required {
		display: inline-block;
		color: $font-primary-color;
		background: $yello-color;
		font-size: 1.2rem;
		line-height: 1;
		padding: 4px 6px;
		border-radius: 2px;
		vertical-align: middle;
		font-weight: 500;
		margin-right: 8px;
	}
}

// 管理画面上
.bge_contents {
	.pc-only,
	.sp-only {
		display: block !important;	// stylelint-disable-line declaration-no-important
	}

	h2 {
		width: 100%;
		margin-left: 0;
		margin-right: 0;
	}

	.index-block {
		.bgt-grid {
			> div {
				&[data-bgt="image-link"] {
					* {
						height: inherit !important;	// stylelint-disable-line declaration-no-important
					}

					.bgt-box__image-container {
						img {
							height: inherit !important;	// stylelint-disable-line declaration-no-important
						}
					}
				}

				&[data-bgt="ckeditor"] {
					height: inherit !important;	// stylelint-disable-line declaration-no-important

					div {
						height: inherit !important;	// stylelint-disable-line declaration-no-important
					}

					a {
						height: inherit !important;	// stylelint-disable-line declaration-no-important
					}
				}
			}
		}
	}

	.nav-local,
	.anchor-link {
		a {
			height: inherit !important;	// stylelint-disable-line declaration-no-important
		}
	}

	.oneday-nav {
		ul {
			li {
				a {
					height: inherit !important;	// stylelint-disable-line declaration-no-important
				}
			}
		}
	}


		.oneday-ttl {
			& + .oneday-box {
				margin: 0 !important;	// stylelint-disable-line declaration-no-important

				@media (--sm-lte) {
					margin: 0 !important;	// stylelint-disable-line declaration-no-important
				}
			}

			& + .oneday-ttl {
				margin: 0 !important;	// stylelint-disable-line declaration-no-important

				@media (--sm-lte) {
					margin: 0 !important;	// stylelint-disable-line declaration-no-important
				}
			}
		}
}

// stylelint-enable selector-class-pattern, selector-nested-pattern

// stylelint-disable unit-blacklist

// # タイポグラフィ
//
// 10(base font size) / 16(Browser Default font size) = 0.625em * 100 = 62.5%
$root-font-size: 62.5%;
$base-font-size: 1.6rem; // 16pt base
$base-line-height: 1.7;
$indent-sub-list: 2em;

// # 寸法
//
$breakpoint-xs: 576px;
$breakpoint-sm: 768px;
$breakpoint-md: 992px;
$breakpoint-lg: 1200px;
$page-viewport-width: $breakpoint-lg;
$page-base-width: 1000px;
$page-viewport-side-margin: 30px;
$page-viewport-side-margin-xs: 20px;

@custom-media --xs /* xsのみ */ (max-width: $breakpoint-xs);
@custom-media --sm /* smのみ */ (min-width: $breakpoint-xs) and (max-width: resolve($breakpoint-sm - 1px));
@custom-media --md /* mdのみ */ (min-width: $breakpoint-sm) and (max-width: resolve($breakpoint-md - 1px));
@custom-media --lg /* lgのみ */ (min-width: $breakpoint-md) and (max-width: resolve($breakpoint-lg - 1px));
@custom-media --xl /* xlのみ */ (min-width: $breakpoint-lg);
@custom-media --xs-lte /* xs以下 = xsのみ */ (max-width: resolve($breakpoint-xs - 1px));
@custom-media --sm-lte /* sm以下 */ (max-width: resolve($breakpoint-sm - 1px));
@custom-media --md-lte /* md以下 */ (max-width: resolve($breakpoint-md - 1px));
@custom-media --lg-lte /* lg以下 */ (max-width: resolve($breakpoint-lg - 1px));
@custom-media --xs-gt /* xs超え */ (min-width: $breakpoint-xs);
@custom-media --sm-gt /* sm超え */ (min-width: $breakpoint-sm);
@custom-media --md-gt /* md超え */ (min-width: $breakpoint-md);
@custom-media --lg-gt /* lg超え */ (min-width: $breakpoint-lg);
@custom-media --flex (max-width: resolve($page-base-width + $page-viewport-side-margin * 2));
@custom-media --hr /* high resolution */ (min-resolution: 2dppx);
@custom-media --slider /* high resolution */ (max-width: 1360px);
@custom-media --slider-s /* high resolution */ (max-width: 1160px);

// # 汎用マージン
//
$margin-narrow-xs: 5px;
$margin-narrow-sm: 10px;
$margin-narrow-md: 10px;
$margin-narrow-lg: 15px;
$margin-narrow-xl: 15px;

$margin-wide-xs: 30px;
$margin-wide-sm: 50px;
$margin-wide-md: 50px;
$margin-wide-lg: 80px;
$margin-wide-xl: 80px;

// # カラム
//
$card-cols: 2;
$card-cols-sm: 3;
$card-cols-xs: 1;
$card-cols-side-margin: 30px;
$card-cols-side-sm-margin: 15px;
$card-cols-side-xs-margin: 0px; // stylelint-disable-line length-zero-no-unit
$card-cols-vertical-margin: 40px;
$card-cols-vertical-sm-margin: 20px;
$card-cols-vertical-xs-margin: 30px;

// # メインカラムセンタリング
@define-mixin flexible-width-body {
	width: $page-viewport-width;

	@media (max-width: resolve($page-viewport-width + $page-viewport-side-margin * 2)) {
		max-width: resolve($page-viewport-width + $page-viewport-side-margin * 2);
		padding-left: $page-viewport-side-margin;
		padding-right: $page-viewport-side-margin;
		width: 100%;
	}

	@media (--xs) {
		padding-left: $page-viewport-side-margin-xs;
		padding-right: $page-viewport-side-margin-xs;
	}
}

@define-mixin flexible-width-main-conntents {
	width: $page-base-width;

	@media (max-width: resolve($page-base-width + $page-viewport-side-margin * 2)) {
		max-width: resolve($page-base-width + $page-viewport-side-margin * 2);
		padding-left: $page-viewport-side-margin;
		padding-right: $page-viewport-side-margin;
		width: 100%;
	}
}

$ease          : cubic-bezier(0.25, 0.1, 0.25, 1);
$linear        : cubic-bezier(0, 0, 1, 1);
$easeIn        : cubic-bezier(0.42, 0, 1, 1);
$easeOut       : cubic-bezier(0, 0, 0.58, 1);
$easeInOut     : cubic-bezier(0.42, 0, 0.58, 1);

$easeInSine    : cubic-bezier(0.47, 0, 0.745, 0.715);
$easeOutSine   : cubic-bezier(0.39, 0.575, 0.565, 1);
$easeInOutSine : cubic-bezier(0.445, 0.05, 0.55, 0.95);
$easeInQuad    : cubic-bezier(0.55, 0.085, 0.68, 0.53);
$easeOutQuad   : cubic-bezier(0.25, 0.46, 0.45, 0.94);
$easeInOutQuad : cubic-bezier(0.455, 0.03, 0.515, 0.955);

$easeInCubic    : cubic-bezier(0.55, 0.055, 0.675, 0.19);
$easeOutCubic   : cubic-bezier(0.215, 0.61, 0.355, 1);
$easeInOutCubic : cubic-bezier(0.645, 0.045, 0.355, 1);
$easeInQuart    : cubic-bezier(0.895, 0.03, 0.685, 0.22);
$easeOutQuart   : cubic-bezier(0.165, 0.84, 0.44, 1);
$easeInOutQuart : cubic-bezier(0.77, 0, 0.175, 1);

$easeInQuint    : cubic-bezier(0.755, 0.05, 0.855, 0.06);
$easeOutQuint   : cubic-bezier(0.23, 1, 0.32, 1);
$easeInOutQuint : cubic-bezier(0.86, 0, 0.07, 1);
$easeInExpo     : cubic-bezier(0.95, 0.05, 0.795, 0.035);
$easeOutExpo    : cubic-bezier(0.19, 1, 0.22, 1);
$easeInOutExpo  : cubic-bezier(1, 0, 0, 1);

$easeInCirc     : cubic-bezier(0.6, 0.04, 0.98, 0.335);
$easeOutCirc    : cubic-bezier(0.075, 0.82, 0.165, 1);
$easeInOutCirc  : cubic-bezier(0.785, 0.135, 0.15, 0.86);
$easeInBack     : cubic-bezier(0.6, -0.28, 0.735, 0.045);
$easeOutBack    : cubic-bezier(0.175, 0.885, 0.32, 1.275);
$easeInOutBack  : cubic-bezier(0.68, -0.55, 0.265, 1.55);


/* stylelint-disable */
@define-mixin pc-only {
	display: block;

	@media (--xs-lte) {
		display: none !important;
	}
}

@define-mixin sp-only {
	display: none !important;

	@media (--xs-lte) {
		display: block !important;
	}
}

.pc-only {
	@mixin pc-only;
}

.sp-only {
	@mixin sp-only;
}
/* stylelint-enable */



// stylelint-enable unit-blacklist
